import React, { useRef, useState } from "react"
import { navigate } from "gatsby"
import axios from "axios"
import BounceIndicator from "react-activity/lib/Bounce"
import Layout from "../components/layout"
import $ from "jquery"
import close_image from "../assets/images/icon-close.png"
import DateRangePicker from "react-bootstrap-daterangepicker"
// import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-daterangepicker/daterangepicker.css"
import parse from "html-react-parser"
import "../assets/css/housing-details.css"
import ReactStars from "react-rating-stars-component"
import ReactReadMoreReadLess from "react-read-more-read-less"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import moment from "moment"
import "../assets/css/house-detail.css"
import GoogleMap from "../components/google-map"
import SEO from "../components/seo"
import { toggleDetailPage } from "../state/action"
import { useSelector, useDispatch } from "react-redux"
import getSymbolFromCurrency from 'currency-symbol-map'
import Login from "../components/login"

const AccommodationDetail = housing_content => {
  var locationID = ""
  if (typeof window !== `undefined`) {
    locationID = document.location.search.substring(1)
  }
  // console.log("#####################url",locationID)
  const URLParams = new URLSearchParams(locationID)
  const [validationError, setValidationError] = useState("")
  const [counter, setCounter] = useState(0)
  const [maxPerson, setMaxPerson] = useState()
  const [personCount, setPersonCount] = useState(1)
  const [amountValue, setAmountValue] = useState()
  const [selectedDate, setSelectedDate] = useState("")
  const [imageList, setImageList] = useState()
  const [pricingResponse, setPricingResponse] = useState()
  const [open, setOpen] = useState(false)
  const [openDate, setOpenDate] = useState(false)
  const [minDate, setMinDate] = useState("")
  const [maxDate, setMaxDate] = useState("")
  const [openDialog, setOpenDialog] = useState(false)
  const [changeHeader, setChangeHeader] = useState(false)
  const [visibleSection, setVisibleSection] = useState()
  const [apartment, setApartment] = useState(false)
  const [reviewAll, setReviewAll] = useState(false)
  const [livingRoom, setLivingRoom] = useState(false)
  const [addAmount, setAddAmount] = useState()
  const [minDays, setMinDays] = useState()
  const [renderData1, setRenderData1] = useState()
  const [offerResponse, setOfferResponse] = useState()
  const headerRef = useRef(null)
  const overviewRef = useRef(null)
  const apartmentRef = useRef(null)
  const operationsRef = useRef(null)
  const billsRef = useRef(null)
  const rentalRef = useRef(null)
  const livingRef = useRef(null)
  const ruleRef = useRef(null)
  const reviewRef = useRef(null)
  const descriptionRef = useRef(null)
  const [currencySymbol,setCurrencySymbol] = useState("€")
  
  const [userID, setUserID] = useState(0)
  const [login, setLogin] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [username, setUsername] = useState(undefined)
  const [phoneError, setphoneError] = useState('');
  const [successMessage,setSuccessMessage] = useState("");
  const [errorMessage,setErrorMessage] = useState("");

  React.useEffect(() => {
		if (typeof window !== `undefined`) {
			if (typeof document !== `undefined`) {
			localStorage.setItem('lastRevistedPage',window.location.pathname)
      localStorage.setItem('lastRevistedPageID',window.location.search)
			}
		}
	});

  let lastKnownScrollPosition = 0
  const dispatch = useDispatch()
  //   var imageList1=["accessibility","bedroom","cable-tv","central-heating","guest","occasional overnight guests","pets-allowed","smoking-allowed","sofa","wi-fi"]
  var imageList1 = [
    "tv-true",
    "tv-false",
    "bed-linen-and-towels-false",
    "bed-linen-and-towels-true",
    "outdoor-area-true",
    "outdoor-area-false",
    "balcony-false",
    "balcony-true",
    "bathtub-false",
    "bathtub-true",
    "bed-line-and-towels-true",
    "bed-line-and-towels-false",
    "cable-tv-false",
    "cable-tv-true",
    "central-heating-false",
    "coffee-table-false",
    "coffee-table-true",
    "desk-false",
    "desk-true",
    "table-true",
    "dishwasher-false",
    "diswasher-true",
    "double-bed-false",
    "double-bed-true",
    "dryer-false",
    "dryer-true",
    "elevator-false",
    "elevator-true",
    "extra-per-tenant-false",
    "extra-per-tenant-true",
    "floor-plan-false",
    "floor-plan-true",
    "freezer-false",
    "freezer-true",
    "fridge-false",
    "fridge-true",
    "microwave-true",
    "microwave-false",
    "oven-false",
    "oven-true",
    "overnight-guests-allowed-true",
    "overnight-guests-allowed-false",
    "person-false",
    "person-true",
    "requirement-true",
    "requirement-false",
    "pets-allowed-false",
    "pets-allowed-true",
    "pots-pans-false",
    "pots-pans-true",
    "private-bathrooms-false",
    "private-bathrooms-true",
    "shower-true",
    "shower-false",
    "single-bed-false",
    "single-bed-true",
    "single-bedroom-false",
    "single-bedroom-true",
    "sink-false",
    "sink-true",
    "smoking-allowed-false",
    "smoking-allowed-true",
    "sofa-bed-false",
    "sofa-bed-true",
    "sofa-true",
    "sofa-false",
    "stove-true",
    "stove-false",
    "toilet-false",
    "toilet-true",
    "wardrobe-true",
    "wardrobe-false",
    "wardrobe-true",
    "washing-machine-true",
    "washing-machine-false",
    "wi-fi-false",
    "wi-fi-true",
    "window-false",
    "window-true",
    "sofa-bed-true",
    "central-heating-true",
    "chairs-false",
    "chairs-true",
    "apartment-false",
    "apartment-true",
    "air-conditioning-false",
    "air-conditioning-true",
    "admin-fee-false",
    "admin-fee-true",
    "accessibility-false",
    "accessibility-true",
    "overnight-guests-allowed-false",
  ]
  const sectionRefs = [
    { section: "Overview", ref: overviewRef },
    { section: "Description", ref: descriptionRef },
    { section: "Apartment", ref: apartmentRef },
    { section: "Operations", ref: operationsRef },
    { section: "Bills", ref: billsRef },
    { section: "Rental", ref: rentalRef },
    { section: "Living", ref: livingRef },
    { section: "Rule", ref: ruleRef },
    { section: "Review", ref: reviewRef },
  ]

  const getDimensions = ele => {
    if (changeHeader && ele) {
      const { height } = ele.getBoundingClientRect()

      const offsetTop = ele.offsetTop
      const offsetBottom = offsetTop + height

      return {
        height,
        offsetTop,
        offsetBottom,
      }
    } else {
      const height = 0
      const offsetTop = 0
      const offsetBottom = 0
      return
    }
  }

  const scrollTo = ele => {
    console.log(ele, "element")
    // ele.scrollIntoView({
    //   behavior: "smooth",
    //   block: "start",
    // });

    window.scrollTo({
      top: $(ele).offset().top - 120,
      behavior: "smooth",
      block: "start",
      /* you can also use 'auto' behaviour
     in place of 'smooth' */
    })
  }

  function doScroll(scrollPos) {
    // Do something with the scroll position
    if (scrollPos < 40) {
      // console.log(scrollPos, "scrollPos")
      return setChangeHeader(false)
    } else {
      // console.log(scrollPos, "scrollPos")
      return setChangeHeader(true)
    }
  }
  React.useEffect(() => {
    if (URLParams.get("id") !== null) {
      if (!URLParams.get("id")) {
        dispatch(toggleDetailPage(false))
        navigate("/housing")
      } else {
        AxiosResponseData1()
      }
    } else {
      dispatch(toggleDetailPage(false))
      navigate("/housing")
    }
  }, [URLParams.get("id")])

  const AxiosResponseData1 = async () => {
    console.log("axiosresponse")
    await axios
      .post(`${process.env.HOUSING_DETAIL_API_URL}`, {
        url: `${process.env.HOUSING_BASE_URL}/v1/offers/${URLParams.get(
          "id"
        )}?locale='en_GB'`, // ${location.state.id}
        token: process.env.UNIVPLACES_BASE_API_KEY,
      })
      .then(function (response) {
        console.log(response, "response1")
        if (response) {
          console.log("=============//>>", response)
          if (response.data.id === "") {
            navigate("/housing")
          } else if (response.data) {
            setOfferResponse(response.data)
            setCurrencySymbol(getSymbolFromCurrency(
              response.data.globalization_aggregate?
                response.data.globalization_aggregate.country?
                  response.data.globalization_aggregate.country.configuration ?
                    response.data.globalization_aggregate.country.configuration.currency_code ?
                     response.data.globalization_aggregate.country.configuration.currency_code
                     :"EUR":"EUR":"EUR":"EUR"))
            setImageList(response.data.photos)
            setMinDays(
              response.data.accommodation_offer.requisites.conditions
                .minimum_nights
            )
            setAmountValue(
              response.data.accommodation_offer.reference_price.amount / 100
            )
            setMaxPerson(
              response.data.accommodation_offer.requisites.conditions.max_guests
            )
            setAddAmount(
              response.data.accommodation_offer.contract.standard
                .extra_per_guest.amount / 100
            )
            setRenderData1(response.data)
            if (
              response.data.accommodation_offer.availabilities.standard_unitary
                .available_periods
            ) {
              setMinDate(
                response.data.accommodation_offer.availabilities
                  .standard_unitary.available_periods[0].start_date
              )
              setMaxDate(
                response.data.accommodation_offer.availabilities
                  .standard_unitary.available_periods[0].end_date
              )
            }
          } else {
            setOfferResponse()
          }
        } else {
          setOfferResponse()
        }
      })
      .then(function (response) {
        // window.location.search = params.toString();
        // let url = new URL(window.location.href)
        // let params = new URLSearchParams(url.search.slice(1))
        // params.set("id",id);
        // window.location.search = params.toString();
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getPricingResponse = async () => {
    console.log("selectedDateeeeeeeeeee", selectedDate)
    if (selectedDate && URLParams.get("id")) {
      var tempDate = selectedDate.split("->")
      // console.log("dddddddddddddd",tempDate)
      if (tempDate.length > 1) {
        let sdate = tempDate[0].trim()
        let ldate = tempDate[1].trim()
        // console.log(sdate,"ddddddd222222",ldate)
        var date1 = moment(sdate).format("YYYY-MM-DD HH:mm:ss")
        var date2 = moment(ldate).format("YYYY-MM-DD HH:mm:ss")
      }
      await axios
        .post(`${process.env.HOUSING_PRICING_API_URL}`, {
          url: `${process.env.HOUSING_BASE_URL}/v1/calculate-pricing`, // ${location.state.id}
          token: process.env.UNIVPLACES_BASE_API_KEY,
          offer_id: parseInt(URLParams.get("id")),
          start_date: date1,
          end_date: date2,
          guests: personCount,
        })
        .then(function (response) {
          if (response) {
            if (response.data) {
              setPricingResponse(response.data)
            } else {
              setPricingResponse("")
            }
          } else {
            setPricingResponse("")
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  const handleQtyminus = () => {
    if (personCount === 1) {
      setPersonCount(1)
    } else {
      setPersonCount(personCount - 1)
      setAmountValue(amountValue - addAmount)
    }
    if (personCount + 1 === 2) {
      getPricingResponse()
    } else {
      getPricingResponse()
    }
  }

  const handleQtyplus = () => {
    setPersonCount(personCount + 1)
    setAmountValue(amountValue + addAmount)
    setValidationError(
      `<span> Adding another tenant increases the rent. It’s +${currencySymbol}` +
        addAmount +
        " a month.</span>"
    )
    if (personCount + 1 === 2) {
      getPricingResponse()
    } else {
      getPricingResponse()
    }
  }

  const handleClose = () => {
    setOpenDate(false)
    setOpenDialog(false)
    setValidationError("")
    setCounter(0)
    // $('.daterangepicker.show-calendar').each(function() {
    //  $(this).hide();
    // });
    if (selectedDate) {
      var tempDate = selectedDate.split("->")
      // console.log("dddddddddddddd",tempDate)
      if (tempDate.length > 1) {
        let sdate = tempDate[0].trim()
        let ldate = tempDate[1].trim()
        // console.log(sdate,"ddddddd222222",ldate)
        var date1 = moment(sdate)
        var date2 = moment(ldate)
        var Difference_In_Days = date2.diff(date1, "days")
        // console.log("dddddddddddddddddd1",Difference_In_Days)
        if (Difference_In_Days < minDays) {
          setSelectedDate("")
        }
      }
    }
  }

  React.useEffect(() => {
    // console.log(
    //   "----------------------------------",
    //   URLParams.get("housing-type")
    // )
    // if(URLParams.get('bedType') && bedType !== URLParams.get('bedType')){
    //     dispatch(toggleBedType(URLParams.get('bedType')))
    // }
    if (counter > 0) {
      setCounter(counter - 1)
    }
    $(document).ready(function () {
      $('input[name="datefilter"]').daterangepicker({
        minDate: minDate ? new Date(minDate) : new Date(),
        maxDate: new Date(maxDate),
        singleDatePicker: false,
        autoUpdateInput: false,
        autoApply: true,
        locale: {
          cancelLabel: "Clear",
        },
      })
      $('input[name="datefilter"]').on(
        "hide.daterangepicker",
        function (ev, picker) {
          $('input[name="datefilter"]').click()
        }
      )
      $(".form-datetime").daterangepicker(
        { autoUpdateInput: false },
        (from_date, to_date) => {
          $(".form-datetime").val(
            from_date.format("DD/MM/YYYY") +
              " - " +
              to_date.format("DD/MM/YYYY")
          )
        }
      )

      $('input[name="datefilter"]').on(
        "apply.daterangepicker",
        function (ev, picker) {
          if (picker.startDate && picker.endDate) {
            var date1 = new Date(picker.startDate.format("MM/DD/YYYY"))
            var date2 = new Date(picker.endDate.format("MM/DD/YYYY"))
            var Difference_In_Time = date2.getTime() - date1.getTime()
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
            $(this).val(
              picker.startDate.format("MM/DD/YYYY") +
                " -> " +
                picker.endDate.format("MM/DD/YYYY")
            )
            if ($(".clear-dates").length > 0) {
              $(".clear-dates").remove()
            }
            if (Difference_In_Days < minDays) {
              setValidationError(
                "<span><i class='fa fa-calendar'></i> This listing has a minimum stay limit of " +
                  minDays +
                  " days.</span>"
              )
              setCounter(200)
              $(this).val("")
              setSelectedDate("")
            } else {
              setSelectedDate(
                picker.startDate.format("MM/DD/YYYY") +
                  " -> " +
                  picker.endDate.format("MM/DD/YYYY")
              )
              setValidationError("")
              setCounter(0)
              setOpenDate(false)
            }
          }
        }
      )
      if (openDate) {
        $('input[name="datefilter"]').click()
      }
      $(".clear-date").on("click", function () {
        $('input[name="datefilter"]').val("")
        setSelectedDate("")
      })

      $(".ranges ul li").on("click", function () {
        $(".calendar").show()
      })

      $('input[name="datefilter"]').on(
        "cancel.daterangepicker",
        function (ev, picker) {
          $(this).val("")
        }
      )
    })
  })

  React.useEffect(() => {
    if (selectedDate) {
      getPricingResponse()
    }
  }, [selectedDate, pricingResponse])

  React.useEffect(() => {
    document.addEventListener("scroll", function (e) {
      lastKnownScrollPosition = window.scrollY

      window.requestAnimationFrame(function () {
        doScroll(lastKnownScrollPosition)
      })
    })
  })

  React.useEffect(() => {
    if (URLParams.get("id") !== null) {
      if (changeHeader) {
        const handleScroll = () => {
          const { height: headerHeight } = getDimensions(headerRef.current)
          const scrollPosition = window.scrollY + headerHeight + 80

          const selected = sectionRefs.find(({ section, ref }) => {
            const ele = ref.current
            if (ele) {
              const { offsetBottom, offsetTop } = getDimensions(ele)
              return scrollPosition > offsetTop && scrollPosition < offsetBottom
            }
          })

          if (selected && selected.section !== visibleSection) {
            setVisibleSection(selected.section)
          } else if (!selected && visibleSection) {
            setVisibleSection(undefined)
          }
        }

        handleScroll()
        window.addEventListener("scroll", handleScroll)
        return () => {
          window.removeEventListener("scroll", handleScroll)
        }
      } else {
        return
      }
    }
  }, [visibleSection, changeHeader, URLParams.get("id")])

  const HandleReviewAll = e => {
    e.preventDefault()
    console.log(e, "review all region")
    setReviewAll(!reviewAll)
  }

  const redirectCheckout = (e,url) => {
    // e.preventDefault();
    if (typeof window !== `undefined`) {
      if (typeof document !== `undefined`) {
        console.log("eeeeeeeeeee", url)
        window.open(url, "_blank")
        //focus to thet window
        window.focus()
        //reload current page
        window.location.reload()
      }
    }
  }

  const loginCheck = e =>{
    if(localStorage.getItem("userId")){
      setOpenDate(true)
      return false
    }
    else{
      e.preventDefault()
      setOpen(true)
      setLogin(true)
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("")
      localStorage.setItem("redirect", true)
      localStorage.setItem("lastPage","housing-page")
      localStorage.setItem("lastPageId",URLParams.get("id"))
    }
  }

  const HandleLivingRoomContent = e => {
    e.preventDefault()
    setLivingRoom(!livingRoom)
  }

  const HandleApartmentContent = e => {
    e.preventDefault()
    setApartment(!apartment)
  }

  const HandleBatch = e => {
    if (e) {
      return e.split("-")[0]
    } else {
      return
    }
  }
  const { allStrapiHousingDetailSeo, allStrapiHomePage } = housing_content.data

  const seoTitle =
    allStrapiHousingDetailSeo !== null
      ? allStrapiHousingDetailSeo.edges[0].node.title
      : ""

  const seoMetaDescription =
    allStrapiHousingDetailSeo !== null
      ? allStrapiHousingDetailSeo.edges[0].node.metaDescription
      : ""

  const seoImage =
    allStrapiHomePage !== null
      ? allStrapiHomePage.edges[0].node.metaImage
        ? allStrapiHomePage.edges[0].node.metaImage.url
        : ""
      : ""
  return (
    <>
      <SEO title={seoTitle} description={seoMetaDescription} image={seoImage} />
      <Layout setTitle={"Accommodation Detail"} changeHeader={changeHeader} tag="housing">
        <Login
            open={open}
            setOpen={setOpen}
            login={login}
            setLogin={setLogin}
            setUsername={setUsername}
            setUserID={setUserID}
            forgotPassword={forgotPassword}
            setForgotPassword={setForgotPassword}
            errorMessage ={errorMessage}
            setErrorMessage ={setErrorMessage}
            successMessage ={successMessage}
            setSuccessMessage ={setSuccessMessage}
            phoneError ={phoneError}
            setphoneError ={setphoneError}
          />
        <div className="wrapper">
          {changeHeader && (
            <div className="sticky">
              <div className="header" ref={headerRef}>
                <button
                  type="button"
                  className={`header_link ${
                    visibleSection === "Apartment" ? "selected" : ""
                  }`}
                  onClick={() => {
                    scrollTo(apartmentRef.current)
                  }}
                >
                  Apartment
                </button>
                <button
                  type="button"
                  className={`header_link ${
                    visibleSection === "Bills" ? "selected" : ""
                  }`}
                  onClick={() => {
                    scrollTo(billsRef.current)
                  }}
                >
                  Bills Included
                </button>
                <button
                  type="button"
                  className={`header_link ${
                    visibleSection === "Rental" ? "selected" : ""
                  }`}
                  onClick={() => {
                    scrollTo(rentalRef.current)
                  }}
                >
                  Rental Conditions
                </button>
                {/*  <button
                      type="button"
                      className={`header_link ${visibleSection === "Operations" ? "selected" : ""}`}
                      onClick={() => {
                        scrollTo(operationsRef.current);
                      }}
                    >
                      Landlord
                    </button>*/}
                {/*renderData1&&renderData1.booking_review_aggregate!==undefined&&renderData1.booking_review_aggregate?.review_aggregates
                      .items.length !== 0 && (  <button
                        type="button"
                        className={`header_link ${
                          visibleSection === "Review" ? "selected" : ""
                        }`}
                        onClick={() => {
                          scrollTo(reviewRef.current)
                        }}
                      >
                        Reviews
                      </button>)*/}
                {/*  <button
                        type="button"
                        className={`header_link ${
                          visibleSection === "Overview" ? "selected" : ""
                        }`}
                        onClick={() => {
                          scrollTo(overviewRef.current)
                        }}
                      >
                        OverView
                      </button>*/}
                {/*renderData1 &&
                        renderData1.property_aggregate.property.floors[0].units.filter(
                          unit => unit.type_code === "living-room"
                        ) !== undefined &&
                        renderData1.property_aggregate.property.floors[0].units.filter(
                          unit => unit.type_code === "living-room"
                        ).length !== 0 && (
                          <button
                            type="button"
                            className={`header_link ${
                              visibleSection === "Living" ? "selected" : ""
                            }`}
                            onClick={() => {
                              scrollTo(livingRef.current)
                            }}
                          >
                            LivingRoom
                          </button>
                          )*/}

                {renderData1 && renderData1.property_aggregate.property.rules && (
                  <button
                    type="button"
                    className={`header_link ${
                      visibleSection === "Rule" ? "selected" : ""
                    }`}
                    onClick={() => {
                      scrollTo(ruleRef.current)
                    }}
                  >
                    House Rules
                  </button>
                )}
              </div>
            </div>
          )}
          {!renderData1 && (
            <div className={"centered"}>
              <BounceIndicator size={25} color={"#0055a4"} />
            </div>
          )}
          <div className="section" id="Overview" ref={overviewRef}>
            {renderData1 && (
              <div className="home-banner univ-details-banner housing-list-banner study-detail">
                <div className="banner-overlay"></div>
                <div className="carousel-wrapper image-column-section">
                  {imageList ? (
                    <div id="banner-slider">
                      <div className="item">
                        <div
                          className="content-wrapper"
                          onClick={e => {
                            setOpenDialog(true)
                            return false
                          }}
                          style={{
                            backgroundImage: `url(${
                              imageList.length > 0
                                ? `${process.env.HOUSING_LIST_IMAGE_URL}/${imageList[0].hash}/x-large.jpg`
                                : ""
                            })`,
                          }}
                        >
                          <div className="univ-details">
                            <h4 className="univ-name">
                              {renderData1.accommodation_offer.title[0].text}
                            </h4>
                            <p className="univ-location">
                              {renderData1.property_aggregate
                                ? renderData1.property_aggregate.property
                                  ? renderData1.property_aggregate.property
                                      .location
                                    ? renderData1.property_aggregate.property
                                        .location.address
                                      ? renderData1.property_aggregate.property
                                          .location.address.street
                                      : ""
                                    : ""
                                  : ""
                                : ""}
                              ,
                              {renderData1.property_aggregate
                                ? renderData1.property_aggregate.property
                                  ? renderData1.property_aggregate.property
                                      .location
                                    ? renderData1.property_aggregate.property
                                        .location.address
                                      ? renderData1.property_aggregate.property
                                          .location.address.city_code
                                      : ""
                                    : ""
                                  : ""
                                : ""}
                            </p>
                          </div>
                          {/*<img
                              className="univ-fig"
                              src={imageList.length>0 ? (`${process.env.HOUSING_LIST_IMAGE_URL}/${imageList[0].hash}/x-large.jpg`) :""}
                              alt=""
                            ></img>*/}
                        </div>
                      </div>
                      <div className="vertical-thumb">
                        <div
                          className="more-image"
                          onClick={e => {
                            setOpenDialog(true)
                            return false
                          }}
                        >
                          <img
                            src={
                              imageList.length > 1
                                ? `${process.env.HOUSING_LIST_IMAGE_URL}/${imageList[1].hash}/small.jpg`
                                : ""
                            }
                          ></img>
                        </div>
                        <div
                          className="more-image"
                          onClick={e => {
                            setOpenDialog(true)
                            return false
                          }}
                        >
                          <img
                            src={
                              imageList.length > 2
                                ? `${process.env.HOUSING_LIST_IMAGE_URL}/${imageList[2].hash}/small.jpg`
                                : ""
                            }
                          ></img>
                        </div>
                        <div
                          className="more-image"
                          onClick={e => {
                            setOpenDialog(true)
                            return false
                          }}
                        >
                          <img
                            src={
                              imageList.length > 3
                                ? `${process.env.HOUSING_LIST_IMAGE_URL}/${imageList[3].hash}/small.jpg`
                                : ""
                            }
                          ></img>
                        </div>
                        {imageList.length > 4 ? (
                          <div
                            className="more-image last"
                            onClick={e => {
                              setOpenDialog(true)
                              return false
                            }}
                          >
                            <img
                              src={
                                imageList.length > 4
                                  ? `${process.env.HOUSING_LIST_IMAGE_URL}/${imageList[4].hash}/small.jpg`
                                  : ""
                              }
                            ></img>
                            <div className="centered-class">More +</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
          {renderData1 && (
            <div className="home university-details housing-details-home study {(openDate ? 'show-dialog' : 'hide-dialog')}">
              <Dialog
                className="banner-dialog"
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="form-dialog"
              >
                <DialogContent>
                  <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    useKeyboardArrows={true}
                    autoFocus={true}
                    dots={false}
                  >
                    {imageList.map(imageContent => (
                      <div className="popupimage">
                        <img
                          src={`${process.env.HOUSING_LIST_IMAGE_URL}/${imageContent.hash}/large.jpg`}
                        ></img>
                      </div>
                    ))}
                  </Carousel>
                </DialogContent>
                <img
                  className="closeicon"
                  onClick={() => {
                    handleClose()
                  }}
                  src={close_image}
                  alt=""
                />
              </Dialog>
              <div className="main-content housing-details-main-content univ-details-main">
                <div className="btn-shares">
                  <button className="btn-wishlist" type="button">
                    Wishlist
                  </button>
                  <button className="btn-share" type="button">
                    Share
                  </button>
                </div>
                <div className="housing-details">
                  <div className="unive-course-details">
                    <div className="d-flex justify-content-between align-items-center">
                      {renderData1.property_aggregate.property.floors.length !==
                        0 &&
                        renderData1.property_aggregate.property.floors[0].units.filter(
                          unit => unit.type_code === "bedroom"
                        ).length !== 0 && (
                          <div className="box established">
                            {/* <div className="image">
                                  <img
                                    src="./assets/images/univ_date.svg"
                                    alt="Television"
                                  />
                                  </div>
                                  {console.log(
                                    renderData1.property_aggregate.property.floors[0].units.filter(
                                      unit => unit.type_code === "bedroom"
                                    )[0]?.subunits[0].metadata.title_translations[0]
                                      .text,
                                    "bedroom"
                                  )}*/}

                            <div className="text">
                              {renderData1.property_aggregate.property.floors
                                .length !== 0 && (
                                <h4 className="heading">
                                  {renderData1.property_aggregate.property.floors[0].units.filter(
                                    unit => unit.type_code === "bedroom"
                                  ).length !== 0
                                    ? "bedroom"
                                    : null}
                                </h4>
                              )}
                            </div>
                          </div>
                        )}
                      <div className="box fees">
                        {/* <div className="image">
                                <img
                                  src="./assets/images/univ_date.svg"
                                  alt="Television"
                                />
                              </div>*/}
                        <div className="text">
                          <h4 className="heading">
                            Up To{" "}
                            {renderData1.accommodation_offer?.requisites
                              .conditions.max_guests
                              ? renderData1.accommodation_offer?.requisites
                                  .conditions.max_guests
                              : ""}{" "}
                            People
                          </h4>
                        </div>
                      </div>
                      <div className="box type-institute">
                        {/* <div className="image">
                              <img
                                src="./assets/images/univ_date.svg"
                                alt="Television"
                              />
                              </div>*/}
                        <div className="text">
                          <h4 className="heading">
                            {renderData1.property_aggregate.property.typology
                              .number_of_bedrooms
                              ? renderData1.property_aggregate.property.typology
                                  .number_of_bedrooms
                              : "Studio in residence"}
                            {renderData1.property_aggregate.property.typology
                              .number_of_bedrooms
                              ? " Bedroom apartment"
                              : ""}
                          </h4>
                        </div>
                      </div>
                      <div className="box ranking">
                        {/* <div className="image">
                                <img
                                  src="./assets/images/univ_date.svg"
                                  alt="Television"
                                />
                              </div>*/}
                        <div className="text">
                          <h4 className="heading">
                            {renderData1.property_aggregate.property.typology
                              .number_of_bathrooms
                              ? renderData1.property_aggregate.property.typology
                                  .number_of_bathrooms
                              : ""}{" "}
                            Bathroom
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {renderData1 && (
                  <div className="container">
                    <div className="housing-details-columns">
                      <div className="housing-details-left">
                        {renderData1.property_aggregate.property.metadata && (
                          <div
                            className="house-details-wrap section"
                            id="Description"
                            // ref={descriptionRef}
                          >
                            <div className="house-details-heading">
                              Place description
                            </div>
                            <p className="house-description-detail">
                              {renderData1.property_aggregate.property
                                .metadata ? (
                                <ReactReadMoreReadLess
                                  charLimit={1000}
                                  readMoreText={"Read more"}
                                  readLessText={"Read less"}
                                >
                                  {
                                    renderData1.property_aggregate.property
                                      .metadata[0].text
                                  }
                                </ReactReadMoreReadLess>
                              ) : null}
                            </p>
                          </div>
                        )}
                        {renderData1 &&
                          renderData1.property_aggregate.property.features
                            ?.length !== 0 &&renderData1.property_aggregate.property.features?.filter(
                              feature => feature.Exists == true
                            ) !== undefined &&
                            renderData1.property_aggregate.property.features?.filter(
                              feature => feature.Exists == true
                            ).length !== 0 &&  (
                            <div className="house-details-wrap">
                              <div className="house-details-heading">
                                Amenties
                              </div>

                              {renderData1 &&
                                renderData1.property_aggregate.property.features
                                  ?.length !== 0 &&
                                renderData1.property_aggregate.property.features?.filter(
                                  feature => feature.Exists == true
                                ) !== undefined &&
                                renderData1.property_aggregate.property.features?.filter(
                                  feature => feature.Exists == true
                                ).length !== 0 && (
                                  <div className="house-facility">
                                    <div
                                      className="facility-card section"
                                      id="Apartment"
                                      ref={apartmentRef}
                                    >
                                      <div className="heading">Apartment</div>
                                      <div className="facilities">
                                        <ul
                                          className={`list list-unstyled ${
                                            apartment ? "more-images" : ""
                                          }`}
                                        >
                                          {/*.filter(feature => feature.Exists === true)*/}
                                          {!apartment &&
                                            renderData1.property_aggregate
                                              .property.features.length !== 0 &&
                                            renderData1.property_aggregate.property.features
                                              .filter(
                                                feature =>
                                                  feature.Exists == true
                                              )
                                              ?.map(
                                                (feature, index) =>
                                                  index < 4 && (
                                                    <li
                                                      key={index}
                                                      className={`list-items ${
                                                        feature.Exists
                                                          ? ""
                                                          : "disabled"
                                                      }`}
                                                    >
                                                      <div className="image">
                                                        {imageList1.some(
                                                          e =>
                                                            e ==
                                                            `${feature.Code}-${feature.Exists}`
                                                        ) ? (
                                                          <div className="image-div">
                                                            <div className="image unhover">
                                                              <img
                                                                src={require(`../assets/images/housingicons/housing/${feature.Code}-false.png`)}
                                                                alt=""
                                                              />
                                                            </div>
                                                            <div
                                                              className="image hover"
                                                              style={{
                                                                display: "none",
                                                              }}
                                                            >
                                                              <img
                                                                src={require(`../assets/images/housingicons/housing/${feature.Code}-true.png`)}
                                                                alt=""
                                                              />
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div className="image-div">
                                                            <div className="image unhover">
                                                              <img
                                                                src={require(`../assets/images/housingicons/housing/window-false.png`)}
                                                                alt=""
                                                              />
                                                            </div>
                                                            <div
                                                              className="image hover"
                                                              style={{
                                                                display: "none",
                                                              }}
                                                            >
                                                              <img
                                                                src={require(`../assets/images/housingicons/housing/window-true.png`)}
                                                                alt=""
                                                              />
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                      <div className="text captalization">
                                                        {feature.Code
                                                          ? feature.Code
                                                          : ""}
                                                      </div>
                                                    </li>
                                                  )
                                              )}
                                          {/*.filter(feature => feature.Exists === true)*/}
                                          {apartment &&
                                            renderData1.property_aggregate.property.features?.filter(
                                              feature => feature.Exists == true
                                            ).length !== 0 &&
                                            renderData1.property_aggregate.property.features
                                              .filter(
                                                feature =>
                                                  feature.Exists == true
                                              )
                                              ?.map((feature, index) => (
                                                <li
                                                  key={index}
                                                  className={`list-items ${
                                                    feature.Exists
                                                      ? ""
                                                      : "disabled"
                                                  }`}
                                                >
                                                  <div className="image">
                                                    {imageList1.some(
                                                      e =>
                                                        e ==
                                                        `${feature.Code}-${feature.Exists}`
                                                    ) ? (
                                                      <div className="image-div">
                                                        <div className="image unhover">
                                                          <img
                                                            src={require(`../assets/images/housingicons/housing/${feature.Code}-false.png`)}
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div
                                                          className="image hover"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                        >
                                                          <img
                                                            src={require(`../assets/images/housingicons/housing/${feature.Code}-true.png`)}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div className="image-div">
                                                        <div className="image unhover">
                                                          <img
                                                            src={require(`../assets/images/housingicons/housing/window-false.png`)}
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div
                                                          className="image hover"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                        >
                                                          <img
                                                            src={require(`../assets/images/housingicons/housing/window-true.png`)}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div className="text captalization">
                                                    {feature.Code
                                                      ? feature.Code
                                                      : ""}
                                                  </div>
                                                </li>
                                              ))}
                                          {renderData1 &&
                                          renderData1.property_aggregate.property.features?.filter(
                                            feature => feature.Exists == true
                                          )?.length > 4 ? (
                                            <span
                                              className={
                                                apartment
                                                  ? "see-less"
                                                  : "see-more"
                                              }
                                              onClick={e =>
                                                HandleApartmentContent(e)
                                              }
                                            >
                                              {apartment
                                                ? "See less"
                                                : "See more"}
                                            </span>
                                          ) : null}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {renderData1 &&
                                renderData1.property_aggregate.property.floors[0].units.filter(
                                  unit => unit.type_code === "living-room"
                                ) !== undefined &&
                                renderData1.property_aggregate.property.floors[0].units.filter(
                                  unit => unit.type_code === "living-room"
                                ).length !== 0 &&
                                renderData1.property_aggregate.property.floors[0].units.filter(
                                  unit => unit.type_code === "living-room"
                                )[0]?.features &&
                                renderData1.property_aggregate.property.floors[0].units
                                  .filter(
                                    unit => unit.type_code === "living-room"
                                  )[0]
                                  ?.features?.filter(
                                    feature => feature.Exists == true
                                  )?.length !== 0 && (
                                  <div
                                    className="house-facility section"
                                    id="Living"
                                    ref={livingRef}
                                  >
                                    <div className="facility-card">
                                      <div className="heading">Living room</div>
                                      <div className="facilities">
                                        <ul
                                          className={`list list-unstyled ${
                                            livingRoom ? "more-images" : ""
                                          }`}
                                        >
                                          {!livingRoom &&
                                            renderData1.property_aggregate.property.floors[0].units
                                              .filter(
                                                unit =>
                                                  unit.type_code ===
                                                  "living-room"
                                              )[0]
                                              ?.features?.filter(
                                                feature =>
                                                  feature.Exists == true
                                              )
                                              ?.map((feature, index) =>
                                                index < 4 ? (
                                                  <li
                                                    key={index}
                                                    className={`list-items ${
                                                      feature.Exists === true
                                                        ? ""
                                                        : "disabled"
                                                    }`}
                                                  >
                                                    <div className="image">
                                                      {imageList1.some(
                                                        e =>
                                                          e ==
                                                          `${feature.Code}-${feature.Exists}`
                                                      ) ? (
                                                        <div className="image-div">
                                                          <div className="image unhover">
                                                            <img
                                                              src={require(`../assets/images/housingicons/housing/${feature.Code}-false.png`)}
                                                              alt=""
                                                            />
                                                          </div>
                                                          <div
                                                            className="image hover"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                          >
                                                            <img
                                                              src={require(`../assets/images/housingicons/housing/${feature.Code}-true.png`)}
                                                              alt=""
                                                            />
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <div className="image-div">
                                                          <div className="image unhover">
                                                            <img
                                                              src={require(`../assets/images/housingicons/housing/window-false.png`)}
                                                              alt=""
                                                            />
                                                          </div>
                                                          <div
                                                            className="image hover"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                          >
                                                            <img
                                                              src={require(`../assets/images/housingicons/housing/window-true.png`)}
                                                              alt=""
                                                            />
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div className="text captalization">
                                                      {feature.Code
                                                        ? feature.Code
                                                        : ""}
                                                    </div>
                                                  </li>
                                                ) : null
                                              )}
                                          {livingRoom &&
                                            renderData1.property_aggregate
                                              .property.floors.length !== 0 &&
                                            renderData1.property_aggregate.property.floors[0].units
                                              .filter(
                                                unit =>
                                                  unit.type_code ===
                                                  "living-room"
                                              )[0]
                                              ?.features?.filter(
                                                feature =>
                                                  feature.Exists == true
                                              )
                                              ?.map((feature, index) => (
                                                <li
                                                  key={index}
                                                  className={`list-items ${
                                                    feature.Exists === true
                                                      ? ""
                                                      : "disabled"
                                                  }`}
                                                >
                                                  <div className="image">
                                                    {imageList1.some(
                                                      e =>
                                                        e ==
                                                        `${feature.Code}-${feature.Exists}`
                                                    ) ? (
                                                      <div className="image-div">
                                                        <div className="image unhover">
                                                          <img
                                                            src={require(`../assets/images/housingicons/housing/${feature.Code}-false.png`)}
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div
                                                          className="image hover"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                        >
                                                          <img
                                                            src={require(`../assets/images/housingicons/housing/${feature.Code}-true.png`)}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div className="image-div">
                                                        <div className="image unhover">
                                                          <img
                                                            src={require(`../assets/images/housingicons/housing/window-false.png`)}
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div
                                                          className="image hover"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                        >
                                                          <img
                                                            src={require(`../assets/images/housingicons/housing/window-true.png`)}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div className="text captalization">
                                                    {feature.Code
                                                      ? feature.Code
                                                      : ""}
                                                  </div>
                                                </li>
                                              ))}

                                          {renderData1 &&
                                          renderData1.property_aggregate
                                            .property.floors.length !== 0 &&
                                          renderData1.property_aggregate.property.floors[0].units
                                            .filter(
                                              unit =>
                                                unit.type_code === "living-room"
                                            )[0]
                                            ?.features?.filter(
                                              feature => feature.Exists == true
                                            )?.length > 4 ? (
                                            <span
                                              className={
                                                !livingRoom
                                                  ? "see-more"
                                                  : "see-less"
                                              }
                                              onClick={e =>
                                                HandleLivingRoomContent(e)
                                              }
                                            >
                                              {!livingRoom
                                                ? "See more"
                                                : "See less"}
                                            </span>
                                          ) : null}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}

                        <div
                          className="house-details-wrap bills-included section"
                          id="Bills"
                          ref={billsRef}
                        >
                          <div className="house-details-heading">
                            Bills included
                          </div>
                          <div className="bills">
                            <div className="bill bills-left">
                              <ul className="list list-unstyled">
                                <li className="bills-list">
                                  <h3 className="heading">To pay per month</h3>
                                  <p className="content">
                                    <span>Monthly rent</span>
                                    {renderData1.accommodation_offer?.contract
                                      .standard.rents.length !== 0 ? (
                                      <span>
                                        {currencySymbol}
                                        {renderData1.accommodation_offer
                                          ?.contract?.standard?.rents[1]
                                          ?.amount / 100}
                                      </span>
                                    ) : (
                                      <span>{currencySymbol} 0</span>
                                    )}
                                  </p>
                                </li>

                                <li className="bills-list">
                                  <h3 className="heading">Bills</h3>
                                  <p className="content">
                                    <span>Electricity</span>
                                    <span>
                                      {renderData1.accommodation_offer.costs
                                        .bills.electricity.included ? (
                                        <strong>Included</strong>
                                      ) : (
                                        <strong>Excluded</strong>
                                      )}
                                    </span>
                                  </p>
                                  <p className="content">
                                    <span>Water</span>
                                    <span>
                                      {renderData1.accommodation_offer.costs
                                        .bills.water.included ? (
                                        <strong>Included</strong>
                                      ) : (
                                        <strong>Excluded</strong>
                                      )}
                                    </span>
                                  </p>
                                  <p className="content">
                                    <span>Gas</span>
                                    <span>
                                      {renderData1.accommodation_offer.costs
                                        .bills.gas.included ? (
                                        <strong>Included</strong>
                                      ) : (
                                        <strong>Excluded</strong>
                                      )}
                                    </span>
                                  </p>
                                  <p className="content">
                                    <span>wifi</span>
                                    <span>
                                      {renderData1.accommodation_offer.costs
                                        .bills.internet.included ? (
                                        <strong>Included</strong>
                                      ) : (
                                        <strong>Excluded</strong>
                                      )}
                                    </span>
                                  </p>
                                </li>

                                <li className="bills-list">
                                  <h3 className="heading">To pay move in</h3>
                                  <p className="content">
                                    <span>Deposit</span>
                                    <span className="captalization">
                                      <strong>
                                        {renderData1.accommodation_offer
                                          .contract.deposit.value.amount !== 0
                                          ? currencySymbol +
                                            renderData1.accommodation_offer
                                              .contract.deposit.value.amount /
                                              100
                                          : renderData1.accommodation_offer
                                              .contract.deposit?.type
                                          ? renderData1.accommodation_offer
                                              .contract.deposit.type
                                          : "Equal to first rent"}
                                      </strong>
                                    </span>
                                  </p>
                                </li>
                              </ul>
                            </div>
                            <div className="bill bills-right">
                              <ul className="list list-unstyled">
                                {renderData1.accommodation_offer.costs.services
                                  .cleaning.periodicity && (
                                  <li className="bills-list">
                                    <h3 className="heading">
                                      Services & Expenses
                                    </h3>
                                    <p className="content">
                                      <span>Cleaning service</span>
                                      <span className="captalization">
                                        {renderData1.accommodation_offer.costs
                                          .services.cleaning.periodicity ===
                                        "unknown"
                                          ? "Not included"
                                          : renderData1.accommodation_offer
                                              .costs.services.cleaning
                                              .periodicity}
                                      </span>
                                    </p>
                                  </li>
                                )}

                                <li className="bills-list">
                                  <h3 className="heading">Details</h3>
                                  <p className="content">
                                    <span>Type of contract</span>
                                    <span className="captalization">
                                      <strong>
                                        {renderData1.accommodation_offer
                                          .contract.type
                                          ? renderData1.accommodation_offer
                                              .contract.type
                                          : ""}
                                      </strong>
                                    </span>
                                  </p>
                                  <p className="content">
                                    <span>Cancellation policy</span>
                                    <span className="captalization">
                                      <strong>
                                        {renderData1.accommodation_offer
                                          .requisites.conditions
                                          .cancellation_policy
                                          ? renderData1.accommodation_offer
                                              .requisites.conditions
                                              .cancellation_policy
                                          : ""}
                                      </strong>
                                    </span>
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div
                          className="house-details-wrap house-rental-conditions"
                          id="Rental"
                          ref={rentalRef}
                        >
                          <div className="house-details-heading">
                            Rental conditions
                          </div>

                          <div className="coditions">
                            <ul className="list list-unstyled">
                              <li className="list-items">
                                <div className="image">
                                  <div className="image-div">
                                    <div className="image unhover">
                                      <img
                                        src={require("../assets/images/housingicons/housing/contract-false.png")}
                                        alt="Television"
                                      />
                                    </div>
                                    <div
                                      className="image hover"
                                      style={{ display: "none" }}
                                    >
                                      <img
                                        src={require("../assets/images/housingicons/housing/contract-true.png")}
                                        alt="Television"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="text">
                                  <h4 className="heading">Contract</h4>
                                  <p className="sub-heading">
                                    {renderData1.accommodation_offer.contract
                                      .type
                                      ? renderData1.accommodation_offer.contract
                                          .type
                                      : ""}
                                  </p>
                                </div>
                              </li>

                              <li className="list-items">
                                <div className="image">
                                  <div className="image-div">
                                    <div className="image unhover">
                                      <img
                                        src={require("../assets/images/housingicons/housing/bills-false.png")}
                                        alt="Television"
                                      />
                                    </div>
                                    <div
                                      className="image hover"
                                      style={{ display: "none" }}
                                    >
                                      <img
                                        src={require("../assets/images/housingicons/housing/bills-true.png")}
                                        alt="Television"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="text">
                                  <h4 className="heading">Bills</h4>
                                  <p className="sub-heading">
                                    {" "}
                                    {renderData1.accommodation_offer.costs.bills
                                      .maximum.capped
                                      ? "Included"
                                      : "Not included"}
                                  </p>
                                </div>
                              </li>

                              <li className="list-items">
                                <div className="image">
                                  <div className="image-div">
                                    <div className="image unhover">
                                      <img
                                        src={require("../assets/images/housingicons/housing/cancellation-policy-false.png")}
                                        alt="Television"
                                      />
                                    </div>
                                    <div
                                      className="image hover"
                                      style={{ display: "none" }}
                                    >
                                      <img
                                        src={require("../assets/images/housingicons/housing/cancellation-policy-true.png")}
                                        alt="Television"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="text">
                                  <h4 className="heading">
                                    Cancellation policy
                                  </h4>
                                  <p className="sub-heading">
                                    {renderData1.accommodation_offer.requisites
                                      .conditions.cancellation_policy
                                      ? renderData1.accommodation_offer
                                          .requisites.conditions
                                          .cancellation_policy
                                      : ""}
                                  </p>
                                </div>
                              </li>

                              <li className="list-items">
                                <div className="image">
                                  <div className="image-div">
                                    <div className="image unhover">
                                      <img
                                        src={require("../assets/images/housingicons/housing/deposit-false.png")}
                                        alt="Television"
                                      />
                                    </div>
                                    <div
                                      className="image hover"
                                      style={{ display: "none" }}
                                    >
                                      <img
                                        src={require("../assets/images/housingicons/housing/deposit-true.png")}
                                        alt="Television"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="text">
                                  <h4 className="heading">Security Deposit</h4>
                                  <p className="sub-heading">
                                    {renderData1.accommodation_offer.contract
                                      .deposit.value.amount !== 0
                                      ? currencySymbol +
                                        renderData1.accommodation_offer.contract
                                          .deposit.value.amount /
                                          100
                                      : renderData1.accommodation_offer.contract
                                          .deposit?.type
                                      ? renderData1.accommodation_offer.contract
                                          .deposit.type
                                      : "Equal to first rent"}
                                  </p>
                                </div>
                              </li>

                              {renderData1.accommodation_offer.contract
                                .admin_fee.value.amount &&
                              (renderData1.accommodation_offer.contract
                                .admin_fee.value.amount ||
                                renderData1.accommodation_offer.contract
                                  .variable_admin_fee.default_admin_fee?.value
                                  ?.amount) ? (
                                <li className="list-items">
                                  <div className="image">
                                    <div className="image-div">
                                      <div className="image unhover">
                                        {/* renderData1.accommodation_offer
                                              .contract.variable_admin_fee
                                       .default_admin_fee?.value?.amount*/}
                                        <img
                                          src={require("../assets/images/housingicons/housing/admin-fee-false.png")}
                                          alt="Television"
                                        />
                                      </div>
                                      <div
                                        className="image hover"
                                        style={{ display: "none" }}
                                      >
                                        <img
                                          src={require("../assets/images/housingicons/housing/admin-fee-true.png")}
                                          alt="Television"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text">
                                    <h4 className="heading">Admin fee</h4>
                                    <p className="sub-heading">
                                      {currencySymbol}
                                      {renderData1.accommodation_offer.contract
                                        .admin_fee.value.amount
                                        ? renderData1.accommodation_offer
                                            .contract.admin_fee.value.amount /
                                          100
                                        : renderData1.accommodation_offer
                                            .contract.variable_admin_fee
                                            .default_admin_fee?.value?.amount /
                                          100}
                                    </p>
                                  </div>
                                </li>
                              ) : null}

                              <li className="list-items">
                                <div className="image">
                                  <div className="image-div">
                                    <div className="image unhover">
                                      <img
                                        src={require("../assets/images/housingicons/housing/minimum-stay-false.png")}
                                        alt="Television"
                                      />
                                    </div>
                                    <div
                                      className="image hover"
                                      style={{ display: "none" }}
                                    >
                                      <img
                                        src={require("../assets/images/housingicons/housing/minimum-stay-true.png")}
                                        alt="Television"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="text">
                                  <h4 className="heading">Minimum Stay</h4>
                                  <p className="sub-heading">
                                    {renderData1.accommodation_offer.requisites
                                      .conditions.minimum_nights
                                      ? renderData1.accommodation_offer
                                          .requisites.conditions.minimum_nights
                                      : ""}{" "}
                                    nights
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {renderData1 &&
                          renderData1.property_aggregate.property.rules && (
                            <div
                              className="house-details-wrap house-rental-conditions house-rules section"
                              id="Rule"
                              ref={ruleRef}
                            >
                              <div className="house-details-heading">
                                House rules
                              </div>
                              <div className="rules-card">
                                <ul className="list list-unstyled">
                                  {renderData1.accommodation_offer?.restrictions?.gender?.filter(
                                    e => e === "males"
                                  ).length !== 0 &&
                                    renderData1.accommodation_offer?.restrictions?.gender
                                      ?.filter(e => e === "males")
                                      .map((e, index) => (
                                        <li key={index} className="rules-list">
                                          <div className="image">
                                            <div className="image-div">
                                              <div className="image unhover">
                                                <img
                                                  src={require(`../assets/images/housingicons/housing/female-true-normal.png`)}
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                className="image hover"
                                                style={{ display: "none" }}
                                              >
                                                <img
                                                  src={require(`../assets/images/housingicons/housing/female-true-hover.png`)}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="text">
                                            Female only
                                          </div>
                                        </li>
                                      ))}
                                  {renderData1.property_aggregate.property.rules?.map(
                                    (rule, index) =>
                                      index < 10 && (
                                        <li
                                          key={index}
                                          className={`rules-list ${
                                            rule.exists === true
                                              ? ""
                                              : "disabled"
                                          }`}
                                        >
                                          <div className="image">
                                            {imageList1.some(
                                              e =>
                                                e ==
                                                `${rule.code}-${rule.exists}`
                                            ) ? (
                                              rule.exists ? (
                                                <div className="image-div">
                                                  <div className="image unhover">
                                                    <img
                                                      src={require(`../assets/images/housingicons/housing/${rule.code}-${rule.exists}-normal.png`)}
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div
                                                    className="image hover"
                                                    style={{ display: "none" }}
                                                  >
                                                    <img
                                                      src={require(`../assets/images/housingicons/housing/${rule.code}-${rule.exists}-hover.png`)}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              ) : (
                                                <img
                                                  src={require(`../assets/images/housingicons/housing/${rule.code}-${rule.exists}-hover.png`)}
                                                  alt=""
                                                />
                                              )
                                            ) : (
                                              <div className="image-div">
                                                <div className="image unhover">
                                                  <img
                                                    src={require(`../assets/images/housingicons/housing/window-false.png`)}
                                                    alt=""
                                                  />
                                                </div>
                                                <div
                                                  className="image hover"
                                                  style={{ display: "none" }}
                                                >
                                                  <img
                                                    src={require(`../assets/images/housingicons/housing/window-true.png`)}
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          <div className={`text captalization ${rule.exists}`}>
                                            {rule.code ? (rule.code).replace("-allowed",""): ""}
                                          </div>
                                        </li>
                                      )
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}

                        {renderData1 &&
                          renderData1.property_aggregate &&
                          renderData1.property_aggregate.property.location.geo
                            .longitude && (
                            <div className="house-details-wrap house-location">
                              <div className="house-details-heading">
                                Where you'll be
                              </div>

                              <GoogleMap
                                housingDetails={true}
                                latitude={
                                  renderData1.property_aggregate.property
                                    .location.geo.latitude
                                }
                                longitude={
                                  renderData1.property_aggregate.property
                                    .location.geo.longitude
                                }
                              />
                            </div>
                          )}

                        {/*renderData1&&renderData1.booking_review_aggregate!==undefined&&renderData1.booking_review_aggregate.review_aggregates
                                .items.length !== 0 && (
                                <div className="house-details-wrap contents section" id="Review" ref={reviewRef}>
                                  <h4 className="house-details-heading">
                                    Reviews{" "}
                                    <a className="reviews" href="#">
                                      (
                                      {
                                        renderData1.booking_review_aggregate?.review_aggregates.items.length
                                      }{" "}
                                      reviews)
                                    </a>
                                  </h4>
                                  <div className="reviews-view-wrap">
                                    {!reviewAll &&renderData1&& renderData1.booking_review_aggregate!==undefined &&
                                      renderData1.booking_review_aggregate?.review_aggregates.items.length !== 0 &&
                                      renderData1.booking_review_aggregate?.review_aggregates.items.map(
                                        (review, index) =>
                                          index < 5 && (
                                            <div key={index} className="reviews-view">
                                              <div className="reviews-left">
                                                <div className="review-fig">
                                                  <img
                                                    src={require("../assets/images/default-profile.png")}
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="details">
                                                  <h3 className="reviwer-name">
                                                    {review.guest.full_name.first
                                                      ? review.guest.full_name.first
                                                      : ""}
                                                  </h3>
                                                  <h5 className="location">
                                                    {review.guest_profile
                                                      .advanced_info?.nationality
                                                      ? review.guest_profile
                                                          .advanced_info?.nationality
                                                      : ""}
                                                  </h5>
                                                  <span className="reviwer-batch">
                                                    Batch-
                                                    {HandleBatch(
                                                      review.guest.move_in_out.start
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="reviews-right">
                                                <span className="ratings">
                                                  <ReactStars
                                                    count={5}
                                                    value={
                                                      review.review
                                                        .accommodation_provider_review
                                                        .content.grades_average || 0
                                                    }
                                                    size={24}
                                                    edit={false}
                                                    activeColor="#ffd700"
                                                  />
                                                </span>
                                                <h4 className="sub-title">
                                                  {review.guest.about_stay
                                                    .purpose_of_stay
                                                    ? review.guest.about_stay
                                                        .purpose_of_stay
                                                    : ""}
                                                </h4>
                                                <p className="reviewers-review">
                                                  <ReactReadMoreReadLess
                                                    charLimit={100}
                                                    readMoreText={"Read more"}
                                                    readLessText={"Read less"}
                                                  >
                                                    {review.review
                                                      .accommodation_provider_review
                                                      .content.message
                                                      ? review.review
                                                          .accommodation_provider_review
                                                          .content.message
                                                      : ""}
                                                  </ReactReadMoreReadLess>
                                                </p>
                                              </div>
                                            </div>
                                          )
                                      )}

                                    {reviewAll &&renderData1&&
                                      renderData1.booking_review_aggregate!==undefined && renderData1.booking_review_aggregate.review_aggregates.items.map(
                                        (review, index) => (
                                          <div key={index} className="reviews-view section" id="Review" ref={reviewRef}>
                                            <div className="reviews-left">
                                              <div className="review-fig">
                                                <img
                                                  src={require("../assets/images/default-profile.png")}
                                                  alt=""
                                                />
                                              </div>
                                              <div className="details">
                                                <h3 className="reviwer-name">
                                                  {review.guest.full_name.first
                                                    ? review.guest.full_name.first
                                                    : ""}
                                                </h3>
                                                <h5 className="location">
                                                  {review.guest_profile.advanced_info
                                                    ?.nationality
                                                    ? review.guest_profile
                                                        .advanced_info?.nationality
                                                    : ""}
                                                </h5>
                                                <span className="reviwer-batch">
                                                  Batch-
                                                  {HandleBatch(
                                                    review.guest.move_in_out.start
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="reviews-right">
                                              <span className="ratings">
                                                <ReactStars
                                                  count={5}
                                                  value={
                                                    review.review
                                                      .accommodation_provider_review
                                                      .content.grades_average || 0
                                                  }
                                                  size={24}
                                                  edit={false}
                                                  activeColor="#ffd700"
                                                />
                                              </span>
                                              <h4 className="sub-title">
                                                {review.guest.about_stay
                                                  .purpose_of_stay
                                                  ? review.guest.about_stay
                                                      .purpose_of_stay
                                                  : ""}
                                              </h4>
                                              <p className="reviewers-review">
                                                <ReactReadMoreReadLess
                                                  charLimit={100}
                                                  readMoreText={"Read more"}
                                                  readLessText={"Read less"}
                                                >
                                                  {review.review
                                                    .accommodation_provider_review
                                                    .content.message
                                                    ? review.review
                                                        .accommodation_provider_review
                                                        .content.message
                                                    : ""}
                                                </ReactReadMoreReadLess>
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      )}
                                   {!reviewAll &&renderData1&&((renderData1.booking_review_aggregate?.review_aggregates.items).length > 5)&& <button
                                      className="btn-all-review"
                                      type="button"
                                      onClick={e => HandleReviewAll(e)}
                                    >
                                     Read more(
                                      <span>
                                        {
                                          ((renderData1.booking_review_aggregate?.review_aggregates.items).length)-5
                                        }
                                      </span>
                                      )
                                    </button>}
                                    {reviewAll && <button
                                      className="btn-all-review"
                                      type="button"
                                      onClick={e => HandleReviewAll(e)}
                                    >
                                     Read less
                                    </button>}
                                  </div>
                                </div>
                  )*/}
                      </div>

                      <div className="housing-details-right">
                        <div className="house-details">
                          <div className="title">
                            <strong>{currencySymbol}{amountValue}</strong>/month
                          </div>
                          <div className="house-available">
                            <div className="top">
                              <div className="date left">
                                <p>
                                  <strong>Move In & Move Out</strong>
                                </p>
                                <input
                                  type="text"
                                  defaultValue={selectedDate}
                                  onClick={e => {
                                    setOpenDate(true)
                                    return false
                                  }}
                                  className="form-control date-input-box"
                                  placeholder={
                                    minDate
                                      ? "Available from " +
                                        moment(minDate).format("ll")
                                      : "MM/DD/YYYY - MM/DD/YYYY"
                                  }
                                  readOnly={true}
                                />
                              </div>
                            </div>
                            <div className="bottom">
                              <div className="members">
                                <p>
                                  <strong>People</strong>
                                </p>
                                <p>{personCount} people</p>
                                {maxPerson > 1 ? (
                                  <>
                                    <span className="mr-30">
                                      <input
                                        type="button"
                                        defaultValue="-"
                                        className="qtyminus"
                                        field="quantity"
                                        onClick={handleQtyminus}
                                      />
                                    </span>
                                    <span> </span>
                                    <span>
                                      <input
                                        type="button"
                                        defaultValue="+"
                                        className="qtyplus"
                                        field="quantity"
                                        onClick={handleQtyplus}
                                        disabled={
                                          personCount >= maxPerson
                                            ? true
                                            : false
                                        }
                                      />
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="heading">Details</div>
                          {selectedDate && pricingResponse ? (
                            <div className="house-rent-details">
                              <p className="content">
                                <span>Monthly Rent</span>
                                <span>
                                  <strong>
                                    {currencySymbol}
                                    {(pricingResponse?.first_payment?.original
                                      ?.amount?
                                      pricingResponse?.first_payment?.original
                                      ?.amount:0) / 100}{" "}
                                  </strong>
                                </span>
                              </p>
                              <p className="content">
                                <span>Bills</span>
                                <span>
                                  <strong>Included</strong>
                                </span>
                              </p>
                              <p className="content">
                                <span>1st Rental payment</span>
                                <span>
                                  <strong>
                                    {currencySymbol}
                                    {(pricingResponse?.first_payment?.original?.amount?
                                      pricingResponse?.first_payment?.original?.amount:0) 
                                      / 100}{" "}
                                  </strong>
                                </span>
                              </p>
                              <p className="content">
                                <span>One-time service fee</span>
                                <span>
                                  <strong>
                                    {currencySymbol}
                                    {(pricingResponse?.fee?.amount?
                                      pricingResponse?.fee?.amount:0) / 100}
                                  </strong>
                                </span>
                              </p>
                              <p className="content">
                                <span>Deposit</span>
                                <span>
                                  <strong>
                                  {currencySymbol}
                                  {(pricingResponse?.deposit?.value?.amount?
                                    pricingResponse?.deposit?.value?.amount:0) / 100}
                                  </strong>
                                </span>
                              </p>
                            </div>
                          ) : (
                            <div className="house-rent-details">
                              <p className="content">
                                Select your dates to confirm availability and
                                check possible expenses.
                              </p>
                            </div>
                          )}
                          <p className="total-rent">
                            <span>Total</span>
                            {selectedDate && pricingResponse ? (
                              <span>
                                {currencySymbol}
                                {parseFloat(
                                 ( pricingResponse?.first_payment?.original?.amount?
                                   pricingResponse?.first_payment?.original?.amount:0) / 100
                                ) +
                                  parseFloat(
                                    (pricingResponse?.fee?.amount?
                                      pricingResponse?.fee?.amount:0) / 100
                                  )}{" "}
                              </span>
                            ) : (
                              <span>{currencySymbol}{amountValue ? amountValue:0 }</span>
                            )}
                          </p>
                          {selectedDate && pricingResponse ? (
                            <div className="cta-primary">
                              <a
                                className="btn-reserve-book"
                                onClick={e => {
                                  redirectCheckout(e,pricingResponse.checkout_url)
                                }}
                              >
                                REQUEST TO BOOK
                              </a>
                            </div>
                          ) : (
                            <div className="cta-primary">
                              <button
                                className="btn-reserve-book"
                                type="button"
                                onClick={e => {
                                  loginCheck(e)
                                }}
                              >
                                RESERVE BOOKING
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <Dialog
            open={openDate}
            onClose={handleClose}
            aria-labelledby="form-dialog"
            className={"housing-dialog"}
          >
            <DialogContent>
              <div className="row">
                <div className="col-sm-4">
                  <input
                    type="text"
                    name="datefilter"
                    defaultValue={selectedDate}
                    className="form-control"
                    placeholder="Select your move-in"
                  />
                  {selectedDate ? (
                    <span className="clear-date">Clear dates</span>
                  ) : (
                    ""
                  )}

                  {/* <RangeDatePicker
                            minDate={minDate ? new Date(minDate): new Date()}
                            maxDate={maxDate ? new Date(maxDate) : ""}
                            startDate={moveInDate ? new Date(moveInDate) :(minDate ? new Date(minDate):new Date())}
                            endDate ={moveOutDate ? new Date(moveOutDate): ""}
                            dateFormat="DD MMM, YYYY"
                            onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                            startDatePlaceholder="Select your move-in"
                            endDatePlaceholder="Select your move-out"
                            disabled={false}
                            className="my-own-class-name"
                            startWeekDay="monday"
                          /> */}
                </div>
                <div className="col-sm-4">
                  <form id="myform" method="POST" action="#">
                    <i className="fa fa-users"></i>
                    <span>
                      <input
                        name="quantity"
                        value={personCount}
                        className="qty"
                      />
                      people{" "}
                    </span>
                    {maxPerson > 1 ? (
                      <>
                        <input
                          type="button"
                          defaultValue="-"
                          className="qtyminus"
                          field="quantity"
                          onClick={handleQtyminus}
                        />
                        <span> </span>
                        <input
                          type="button"
                          defaultValue="+"
                          className="qtyplus"
                          field="quantity"
                          onClick={handleQtyplus}
                          disabled={personCount >= maxPerson ? true : false}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </form>
                </div>
                <div className="col-sm-4">
                  <span className="form-control pricing-value">
                    {currencySymbol}{amountValue}/month
                  </span>
                </div>
              </div>
            </DialogContent>
            <img
              className="closeicon"
              onClick={() => {
                handleClose()
              }}
              src={close_image}
              alt=""
            />

            {validationError && counter > 0 ? (
              <div className="row validation-block">
                <>{parse(validationError)}</>
              </div>
            ) : (
              ""
            )}
          </Dialog>
        </div>
      </Layout>
    </>
  )
}
export default AccommodationDetail

export const query = graphql`
  query {
    allStrapiHousingDetailSeo {
      edges {
        node {
          title
          metaDescription
        }
      }
    }
    allStrapiHomePage {
      edges {
        node {
          metaImage {
            url
          }
        }
      }
    }
  }
`
