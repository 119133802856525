const TOGGLE_CITYNAME = "TOGGLE_CITYNAME"
const TOGGLE_TUTION_FEE_RANGE = "TOGGLE_TUTION_FEE_RANGE"
const TOGGLE_MOVE_IN_DATE = "TOGGLE_MOVE_IN_DATE"
const TOGGLE_MOVE_OUT_DATE="TOGGLE_MOVE_OUT_DATE"
const TOGGLE_LOCALE="TOGGLE_LOCALE"
const TOGGLE_PROPERTY_FEATURE = "TOGGLE_PROPERTY_FEATURE"
const TOGGLE_BED_TYPE = "TOGGLE_BED_TYPE"
const TOGGLE_HOUSING_TYPE="TOGGLE_HOUSING_TYPE"
const TOGGLE_PAGE_NUMBER="TOGGLE_PAGE_NUMBER"
const TOGGLE_CITY_LIST_TYPE="TOGGLE_CITY_LIST_TYPE"
const TOGGLE_MAX_PRICE="TOGGLE_MAX_PRICE"
const TOGGLE_MIN_PRICE="TOGGLE_MIN_PRICE"
const TOGGLE_REPLACE_BED_TYPE="TOGGLE_REPLACE_BED_TYPE"
const TOGGLE_REPLACE_PROPERTY_FEATURE="TOGGLE_REPLACE_PROPERTY_FEATURE"
const TOGGLE_REPLACE_HOUSING_TYPE="TOGGLE_REPLACE_HOUSING_TYPE"
const TOGGLE_RENT_TYPE="TOGGLE_RENT_TYPE";
const TOGGLE_REPLACE_RENT_TYPE="TOGGLE_REPLACE_RENT_TYPE"
const TOGGLE_DETAIL_PAGE="TOGGLE_DETAIL_PAGE"
const RESET="RESET"
export const reset=re=>({
  type:RESET,
  re
})
export const toggleCityName = cityName => ({
  type: TOGGLE_CITYNAME,
  cityName,
})
export const toggleDetailPage = detail => ({
  type: TOGGLE_DETAIL_PAGE,
  detail,
})

export const toggleTutionFeeRange = tutionFeeRange => ({
  type: TOGGLE_TUTION_FEE_RANGE,
  tutionFeeRange,
})
export const toggleMaxPrice = maxPrice => ({
  type: TOGGLE_MAX_PRICE,
  maxPrice,
})
export const toggleMinPrice = minPrice => ({
  type: TOGGLE_MIN_PRICE,
  minPrice,
})
export const toggleMoveInDate = moveInDate => ({
  type: TOGGLE_MOVE_IN_DATE,
  moveInDate,
})
export const toggleMoveOutDate = moveOutDate => ({
  type: TOGGLE_MOVE_OUT_DATE,
  moveOutDate,
})
export const toggleLocale = locale => ({
  type: TOGGLE_LOCALE,
  locale,
})
export const togglePropertyFeature = newItem => ({
  type: TOGGLE_PROPERTY_FEATURE,
  newItem,
})
export const toggleReplacePropertyFeature = Item => ({
  type: TOGGLE_REPLACE_PROPERTY_FEATURE,
  Item,
})
export const toggleBedType = bedItem => ({
  type: TOGGLE_BED_TYPE,
  bedItem,
})
export const toggleReplaceBedType = bedItem => ({
  type: TOGGLE_REPLACE_BED_TYPE,
  bedItem,
})
export const toggleHousingType = housingItem => ({
  type: TOGGLE_HOUSING_TYPE,
  housingItem,
})
export const toggleReplaceHousingType = housingItem => ({
  type: TOGGLE_REPLACE_HOUSING_TYPE,
  housingItem,
})
export const toggleRentType = rentItem => ({
  type: TOGGLE_RENT_TYPE,
  rentItem,
})
export const toggleReplaceRentType = rentItem => ({
  type: TOGGLE_REPLACE_RENT_TYPE,
  rentItem,
})
export const togglePageNumber=pageNumber=>({
  type: TOGGLE_PAGE_NUMBER,
  pageNumber
})
export const toggleCityList=city=>({
  type: TOGGLE_CITY_LIST_TYPE,
  city
})
